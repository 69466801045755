.App {
  min-height: 100%;
  text-align: center;
  margin-top: 10px;
  background-color: white;
}

iframe {
  display: block;
  border: none;
  height: 91vh;
  width: 100vw;
}